<template>
    <el-dialog :visible.sync="visible" :title="title" @close="close">
        <el-form ref="useForm" :model="model" label-width="130px" size="small" :rules="rules">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="利用单位名称" prop="cName">
                        <el-input v-model="model.cName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="所属机构" prop="orgId">
                        <div @click="selectInstitutions">
                            <el-input placeholder="请选择" v-model="model.orgNa"></el-input>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="监管机构" prop="mOrgId">
                        <div @click="selectMonInstitutions">
                            <el-input placeholder="请选择" v-model="model.mOrgNa"></el-input>
                        </div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="单位地址" prop="addr">
                        <el-input v-model="model.addr"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="联系人" prop="contact">
                        <el-input v-model="model.contact"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="联系电话" prop="conPhone">
                        <el-input v-model="model.conPhone" maxlength="16"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="回收类别" prop="recycleTId">
                        <div @click="selecrecycleTs">
                            <el-input placeholder="请选择" v-model="model.recycleTNa"></el-input>
                        </div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="物资" prop="materialT">
                        <el-input v-model="model.materialT"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="统一社会信用代码" prop="creditCode">
                        <el-input v-model="model.creditCode"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="法定代表人" prop="legalPerson">
                        <el-input v-model="model.legalPerson"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="传真号码" prop="fax">
                        <el-input v-model="model.fax"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="邮箱" prop="email">
                        <el-input v-model="model.email"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="备注" prop="remark">
                        <el-input v-model="model.remark"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="营业执照" prop="licenceUrl">
                        <el-upload
                            class="avatar-uploader"
                            :action="uploadImgUrl"
                            :show-file-list="false"
                            :on-success="handleLicenceUrlAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                            :headers="uploadHeader"
                        >
                            <img v-if="model.licenceUrl" :src="model.licenceUrl" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="资质/其他" prop="otherUrl">
                        <el-upload
                            class="avatar-uploader"
                            :action="uploadImgUrl"
                            :show-file-list="false"
                            :on-success="handleOtherAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                            :headers="uploadHeader"
                        >
                            <img v-if="model.otherUrl" :src="model.otherUrl" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <div class="form_handle">
            <el-button type="primary" size="small" @click="saveUnit">确定</el-button>
            <el-button size="small" @click="visible = false">取消</el-button>
        </div>
        <institutions-tree
            refresh
            :showType="showType"
            :expended="expended"
            :modal="false"
            ref="institutions"
            @addInstitutions="addInstitutions"
            title="选择所属机构"
        ></institutions-tree>
        <recycle-types-tree :modal="false" ref="recycle" @addInstitutions="addRecycleType"></recycle-types-tree>
    </el-dialog>
</template>

<script>
    import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
    import RecycleTypesTree from "@/components/pages/admin/common/dialog-tree/recycleTypes_tree.vue";
    export default {
        data() {
            return {
                visible: false,
                isEdit: false,

                showType: [0, 1, 2],
                expended: true,

                model: {
                    cName: "",
                    orgNa: "",
                    orgId: "",
                    mOrgId: "",
                    mOrgNa: "",
                    addr: "",
                    contact: "",
                    conPhone: "",
                    recycleT: "",
                    recycleTNa: "",
                    materialT: "",
                    creditCode: "",
                    legalPerson: "",
                    fax: "",
                    email: "",
                    remark: "",
                    licenceUrl: "",
                    otherUrl: ""
                },

                uploadImgUrl: "/iwastes-admin/sys/user/uploadLogo",
                uploadHeader: {},

                rules: {
                    cName: [{ required: true, message: "利用单位名称不为空", trigger: "blur" }],
                    orgId: [{ required: true, message: "所属机构不为空", trigger: "change" }],
                    mOrgId: [{ required: true, message: "监管机构不为空", trigger: "change" }],
                    addr: [{ required: true, message: "单位地址不为空", trigger: "blur" }],
                    contact: [{ required: true, message: "联系人不为空", trigger: "blur" }],
                    conPhone: [{ required: true, message: "联系人电话不为空", trigger: "blur" }]
                }
            };
        },
        created() {
            this.setUploadHeader();
        },
        methods: {
            close() {
                this.$refs.useForm.resetFields();
                this.model.orgNa = "";
                this.model.mOrgNa = "";
                this.model.regionNa = "";
                this.model.recycleTNa = "";
                this.model.lng = "";
                this.model.lat = "";
            },
            saveUnit() {
                let url = "/unitInfo/add";
                if (this.isEdit) {
                    url = "/unitInfo/update";
                }
                this.$refs.useForm.validate(valide => {
                    if (valide) {
                        this.$http
                            .post(url, {
                                ...this.model,
                                unitType: "1"
                            })
                            .then(res => {
                                this.$message.success({
                                    message: res.msg
                                });
                                this.$emit("update");
                                this.visible = false;
                            });
                    }
                });
            },
            setUploadHeader() {
                let header = {
                    token: this.$util.getStor("token")
                };
                this.uploadHeader = header;
            },
            init(row) {
                if (row) {
                    this.isEdit = true;
                    this.$nextTick(() => {
                        Object.assign(this.model, row);
                    });
                } else {
                    this.isEdit = false;
                }

                this.visible = true;
            },
            addRecycleType(data) {
                this.model.recycleTNa = data.cName;
                this.model.recycleT = data.recycleTId;
            },
            addInstitutions(data, flag) {
                if (flag === "org") {
                    this.model.orgId = data.orgId;
                    this.model.orgNa = data.orgNa;
                } else {
                    this.model.mOrgId = data.orgId;
                    this.model.mOrgNa = data.orgNa;
                }
            },
            // 选择机构树
            selectInstitutions() {
                this.showType = [1];
                this.expended = false;
                this.$refs.institutions.init({ flag: "org" });
            },
            selectMonInstitutions() {
                this.showType = [2];
                this.expended = true;
                this.$refs.institutions.init({ flag: "mOrg" });
            },
            selecrecycleTs() {
                this.$refs.recycle.init();
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === "image/jpeg" || file.type === "image/png";

                if (!isJPG) {
                    this.$message.error("图片只能是JPG或PNG格式");
                }
                return isJPG;
            },
            handleOtherAvatarSuccess({ detail }) {
                this.model.otherUrl = detail;
            },
            handleLicenceUrlAvatarSuccess({ detail }) {
                this.model.licenceUrl = detail;
            }
        },
        computed: {
            title() {
                return this.isEdit ? "编辑" : "新增";
            }
        },
        components: {
            RecycleTypesTree,
            institutionsTree
        }
    };
</script>
<style lang="scss" scoped>
    .back_btn {
        .el-button {
            font-size: 16px;
        }
    }
    .detail_form {
        margin-top: 20px;
        .form_item {
            margin-bottom: 30px;
            .head_title {
                font-size: 18px;
            }
            .head_title::before {
                content: "";
                display: inline-block;
                width: 7px;
                height: 26px;
                margin-right: 5px;
                vertical-align: middle;
                background-color: #3498db;
            }
        }
    }

    .form_handle {
        text-align: center;
    }
</style>
