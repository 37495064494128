<template>
    <div>
        <jy-query :model="form" ref="form" labelWidth="100" class="query_area" size="small">
            <jy-query-item label="利用单位名称" prop="cName">
                <el-input v-model="form.cName"></el-input>
            </jy-query-item>
            <jy-query-item label="所属机构" prop="orgId">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="form.orgNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="回收类别" prop="recycleT">
                <div @click="selecrecycleTs">
                    <el-input placeholder="请选择" v-model="form.recycleTNa"></el-input>
                </div>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck" v-if="btnexist('wastesBasicInfoUserUnitList')">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')" v-if="btnexist('wastesBasicInfoUserUnitReset')">重置</el-button>
                <el-row>
                    <el-button size="small" type="primary" v-on:click="add" v-if="btnexist('wastesBasicInfoUserUnitInsert')">新增</el-button>
                    <export-excel
                        v-if="btnexist('wastesBasicInfoUserUnitExport') && false"
                        url="/unitInfo/export"
                        fileName="利用单位管理"
                        fileType=".xls"
                        size="small"
                    ></export-excel>
                    <el-button v-if="btnexist('wastesBasicInfoUserUnitImport') && false" size="small" type="primary">导入</el-button>
                </el-row>
            </template>
        </jy-query>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="dataList">
                <jy-table-column label="序号" type="index" width="55"></jy-table-column>
                <jy-table-column label="利用单位名称" prop="cName" min-width="120"></jy-table-column>
                <jy-table-column label="所属机构" prop="orgNa" min-width="150"></jy-table-column>
                <jy-table-column label="单位地址" prop="addr" min-width="180"></jy-table-column>
                <jy-table-column label="回收类别" prop="recycleTNa" min-width="120"></jy-table-column>
                <jy-table-column label="联系人" prop="contact" min-width="120"></jy-table-column>
                <jy-table-column label="联系方式" prop="conPhone" min-width="140"></jy-table-column>
                <jy-table-column label="创建人" prop="cUserNa" min-width="120"></jy-table-column>
                <jy-table-column label="创建时间" prop="createT" min-width="160"></jy-table-column>
                <jy-operate v-bind:list="operateList" width="200"></jy-operate>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>

        <add-update ref="addUpdate" @update="getList"></add-update>
        <recycle-types-tree ref="recycle" @addInstitutions="addRecycleType"></recycle-types-tree>
        <institutions-tree :showType="[1]" ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
    </div>
</template>

<script>
    import ExportExcel from "@/components/pages/admin/common/export";
    import AddUpdate from "@/components/pages/admin/basic/useUnit/AddUpdate.vue";
    import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
    import RecycleTypesTree from "@/components/pages/admin/common/dialog-tree/recycleTypes_tree.vue";

    import { btnMixins } from "@/common/js/button.mixin";
    export default {
        created() {
            this.getList();
            this.setOperateList();
        },
        mixins: [btnMixins],
        methods: {
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            async getList() {
                this.loading = true;
                let url = "/unitInfo/pageQuery";
                const { detail } = await this.$http.post(
                    url,
                    {
                        unitInfo: {
                            ...this.form,
                            unitType: "1"
                        },
                        orgIds: [],
                        mOrgIds: [],
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize
                    },
                    { isRequestParam: false }
                );

                this.dataList = detail.list.map(v => {
                    return {
                        ...v.unitInfo,
                        orgId: v.sysOrg && v.sysOrg.orgId,
                        orgNa: v.sysOrg && v.sysOrg.orgNa,
                        mOrgId: v.mSysOrg && v.mSysOrg.orgId,
                        mOrgNa: v.mSysOrg && v.mSysOrg.orgNa,
                        recycleTNa: ""
                    };
                });
                this.total = detail.total;

                const reList = this.dataList.map(v => v.recycleT).filter(v => v);
                if (reList && reList.length) {
                    const { detail: recycles } = await this.$http.post("/recycleType/batchQueryByRecycleIds", reList.join(","));

                    this.dataList.forEach(row => {
                        const recycle = recycles.find(v => v.recycleTId === row.recycleT);
                        if (recycle) {
                            row.recycleTNa = recycle.cName;
                        }
                    });
                }
                this.loading = false;
            },
            // 重置
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.form.orgNa = "";
                this.form.recycleTNa = "";
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            add() {
                this.handleAddUpdate();
            },
            handleAddUpdate(options) {
                this.$refs.addUpdate.init(options);
            },
            handleDelete(row) {
                this.$confirm("是否删除该利用单位?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        let url = "/unitInfo/delete";
                        this.$http.post(url, row.unitId, { isRequestParam: false }).then(res => {
                            this.$message({
                                type: "success",
                                message: res.msg
                            });
                            this.getList();
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除"
                        });
                    });
            },
            addInstitutions(data) {
                this.form.orgId = data.orgId;
                this.form.orgNa = data.orgNa;
            },
            addRecycleType(data) {
                this.form.recycleTNa = data.cName;
                this.form.recycleT = data.recycleTId;
            },
            // 选择机构树
            selectInstitutions() {
                this.$refs.institutions.init();
            },
            selecrecycleTs() {
                this.$refs.recycle.init();
            },
            setOperateList() {
                let l = [
                    {
                        name: "修改",
                        icon: "el-icon-edit-outline",
                        fun: this.handleAddUpdate,
                        isShow: () => {
                            return this.btnexist("wastesBasicInfoUserUnitUpdate");
                        }
                    },
                    {
                        name: "删除",
                        icon: "el-icon-delete",
                        fun: this.handleDelete,
                        isShow: () => {
                            return this.btnexist("wastesBasicInfoUserUnitDelete");
                        }
                    }
                ];
                this.operateList = l;
            }
        },
        data() {
            return {
                loading: false,
                dataList: [],
                total: 0,

                form: {
                    cName: "",
                    recycleT: "",
                    recycleTNa: "",
                    orgId: "",
                    orgNa: ""
                },

                pageIndex: 1,
                pageSize: 10,
                operateList: [],

                btnMenuId: "wastesBasicInfoUserUnit"
            };
        },
        components: {
            ExportExcel,
            AddUpdate,
            institutionsTree,
            RecycleTypesTree
        }
    };
</script>
<style scoped>
    .query_area {
        margin-top: 10px;
    }
</style>
